export default function countdown() {
    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, '0'),
        mm = String(today.getMonth() + 1).padStart(2, '0'),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        setDays = new Date(today.getTime() + 4 * day),
        dayMonth =
            String(setDays.getMonth() + 1).padStart(2, '0') +
            '/' +
            String(setDays.getDate()).padStart(2, '0') +
            '/',
        birthday = dayMonth + yyyy;

    today = mm + '/' + dd + '/' + yyyy;
    if (today > birthday) {
        birthday = dayMonth + nextYear;
    }
    // end

    const countDown = new Date(birthday).getTime(),
        x = setInterval(function () {
            const now = new Date().getTime(),
                distance = countDown - now;

            // Select all instances of the countdown element
            const countdownElements = document.querySelectorAll('.countdown');

            // Update inner text for each countdown element
            countdownElements.forEach(element => {
                const days = String(Math.floor(distance / day)).padStart(2, '0');
                const hours = String(Math.floor((distance % day) / hour)).padStart(2, '0');
                const minutes = String(Math.floor((distance % hour) / minute)).padStart(2, '0');

                element.querySelector('.days').innerText = days;
                element.querySelector('.hours').innerText = hours;
                element.querySelector('.minutes').innerText = minutes;
            });
        }, 0);
}

