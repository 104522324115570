import { Modal } from 'bootstrap'

export default function modal() {
	let modalLinks = [
		{
			selector: '[data-bs-target*="#popup-optin"]',
			modal: 'popup-optin',
		},
		{
			selector: '[data-bs-target*="#popup-terms-and-conditions"]',
			modal: 'popup-terms-and-conditions',
		},
		{
			selector: '[data-bs-target*="#phone-one-popup"]',
			modal: 'phone-one-popup',
		},
		{
			selector: '[data-bs-target*="#phone-two-popup"]',
			modal: 'phone-two-popup',
		},
		{
			selector: '[data-bs-target*="#phone-popup-single"]',
			modal: 'phone-popup-single',
		},
		
	]

	modalLinks.forEach((link) => {
		let links = document.querySelectorAll(link.selector)

		if (links.length) {
			for (var i = 0; i < links.length; i++) {
				links[i].addEventListener('click', (evt) => {
					evt.preventDefault()
					let target = document.querySelector('#' + link.modal)
					new Modal(target).show()
				})
			}
		}
	})

	let modals = document.querySelectorAll('.modal')

	if (modals.length) {
		for (var i = 0; i < modals.length; i++) {
			new Modal(modals[i])
		}
	}

	// Exit Intent Logic
	const exitIntentTrigger = document.querySelector("#exit-intent-trigger")

	// If exit trigger is on current page
	// if(exitIntentTrigger) {
	// 	document.addEventListener("visibilitychange", (e) => {
	// 		if (document.visibilityState === "visible") {
	// 			console.log("page is visible")
	// 		} else {
	// 			console.log("page is not visible")
	// 			alert("yo")
	// 		}
	// 	  });
	// } 
}