export default function initWistiaTriggers() {
	// Grab all the wistia custom play buttons on the page
	let customWistiaBtns = [...document.querySelectorAll('.custom-play-button')]

	// Check if wistia global variable exist. If it doesn't, initialise it.
	window._wq = window._wq || []

	// If there are no custom play buttons, Return.
	if (!customWistiaBtns) return

	// Loop through the buttons and define events/behaviour
	customWistiaBtns.forEach((btn) => {
		let videoID = btn.getAttribute('data-wistia-id'),
			currentContainer = btn.closest('.inner-slide__video'),
			isVideoCover =
			currentContainer.querySelector('.inner-slide__video-cover') || null,
			isTouchDisabled = currentContainer.querySelector('.disable-touch');

		// Assign Videos and button click behaviour
		btn.addEventListener('click', () => {
			_wq.push({
				id: videoID,
				onReady: function (video) {
					video.play()
					video.unmute()
				},
			})

			// Hide the play button when clicked
			btn.style.display = 'none'

			if (isVideoCover) isVideoCover.style.display = 'none'
			if (isTouchDisabled) isTouchDisabled.classList.remove("disable-touch")
		})
	})
}
